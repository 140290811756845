import React, {Component} from 'react';

import {onEnterPress} from "utils/onKeyPress";

class OffcanvasMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.menuItems = React.createRef();
        this.menuSelf = React.createRef();
        this.menuToggle = React.createRef();
    }

    toggleMenu(e) {
        const menuitems = this.menuItems.current;
        const toggle = this.menuToggle.current;
        const {isOpen} = this.state;

        if (!isOpen && toggle) {
            if (!(e.target.isEqualNode(toggle) || toggle.contains(e.target))) {
                return;
            }
        }

        if (menuitems) {
            if ((e.target.isEqualNode(menuitems) || menuitems.contains(e.target))) {
                return;
            }
        }

        this.setState({
            isOpen: !isOpen,
        });
    }

    render() {
        const {isOpen} = this.state;

        return (
            <div>
                <div
                    id="offcanvas-overlay"
                    className={isOpen ? "open" : ""}
                    onClick={::this.toggleMenu}
                    onKeyPress={e => onEnterPress(e, () => this.toggleMenu(e))}
                    role="button"
                    tabIndex={0}
                />
                <div
                    id="offcanvas-menu-container"
                    className={isOpen ? "open" : ""}
                    onClick={::this.toggleMenu}
                    onKeyPress={e => onEnterPress(e, () => this.toggleMenu(e))}
                    role="button"
                    tabIndex={0}
                >
                    <div id="offcanvas-menu" ref={this.menuItems}>
                        <ul className="offcanvas-menu-items offcanvas-menu--top">
                            <li><a href="https://www.veebimajutus.ee">Avaleht</a></li>
                            <li><a href="https://www.veebimajutus.ee/domeenid/">Domeenid</a></li>
                            <li><a href="https://www.veebimajutus.ee/teenused/">Teenused</a></li>
                            <li><a href={DJ_CONST.SITE_URL} ref={this.menuSelf} className="active">Blogi</a></li>
                            <li><a href="https://www.veebimajutus.ee/klienditugi/">Abi</a></li>
                        </ul>
                        <ul className="offcanvas-menu-items offcanvas-menu--bottom">
                            <li>
                                <a className="email" href="https://www.veebimajutus.ee/vaata-emaili/">
                                Loe e-posti
                                </a>
                            </li>
                            <li>
                                <a className="selfservice" href="https://www.veebimajutus.ee/iseteenindus/">
                                Iseteenindus
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        id="offcanvas-toggle"
                        onClick={::this.toggleMenu}
                        ref={this.menuToggle}
                        onKeyPress={e => onEnterPress(e, () => this.toggleMenu(e))}
                        role="button"
                        tabIndex={0}
                    >
                        <img src={`${DJ_CONST.STATIC_URL}styles-src/images/blog/menu.svg`} alt="Menu" />
                        <div className="menu--close" id="menu--close" />
                    </div>
                </div>
            </div>
        );
    }
}

export default OffcanvasMenu;
