import React from 'react';
import PropTypes from 'prop-types';


class FaceBookIframe extends React.PureComponent {
    render() {
        const {needsToSubscribe} = this.props;
        if (needsToSubscribe) {
            return null;
        }

        const isMobile = window.innerWidth < 767;
        const fbBoxWidth = isMobile ? '274' : '440';
        const fbBoxHeight = '130';

        const fbStyles = {
            border: 'none',
            overflow: 'hidden',
            allowTransparency: true,
            scrolling: "no",
            frameBorder: "0px",
            height: `${fbBoxHeight}px`,
            width: `${fbBoxWidth}px`,
        };

        const srcStart = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fveebimajutus";
        const srcProps = `&tabs&width=${fbBoxWidth}&height=${fbBoxHeight}`;
        const srcEnd = "&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId";

        return (
            <div>
                <div className="fb-box">
                    <iframe
                        title="fb-iframe-box"
                        src={`${srcStart}${srcProps}${srcEnd}`}
                        style={fbStyles}
                    />
                </div>
            </div>
        );
    }
}

FaceBookIframe.propTypes = {
    needsToSubscribe: PropTypes.bool,
};

FaceBookIframe.defaultProps = {
    needsToSubscribe: false,
};

export default FaceBookIframe;
