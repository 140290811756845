import React, {Component} from 'react';
import classNames from 'classnames';

import {onEnterPress} from 'utils/onKeyPress';

import SortLinks from './SearchBox/SortLinks';

import {updateQueryParams} from './SearchBox/utils';


class SearchBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            linksHidden: false,
            searchText: '',
        };
    }

    openSearch = () => {
        this.setState({linksHidden: true});
    };

    closeSearch = () => {
        this.setState({searchText: '', linksHidden: false});
    };

    updateSearch = (e) => {
        this.setState({searchText: e.target.value});
    };

    onSearchPress = (e) => {
        const keyCode = e.which || e.keyCode;

        // Perform the search when the user presses enter.
        // Note: the enter key is 13.
        if (keyCode === 13) {
            this.performSearch();
        }
    };

    performSearch = () => {
        const {searchText, linksHidden} = this.state;
        if (linksHidden) {
            updateQueryParams({q: searchText}, false);
        }
    };

    addHidden = (c) => {
        const {linksHidden} = this.state;
        return classNames(c, {hidden: !linksHidden});
    };

    addOpen = (c) => {
        const {linksHidden} = this.state;
        return classNames(c, {open: linksHidden});
    };

    render() {
        const placeholderText = 'Type and hit enter to search...';

        const {linksHidden, searchText} = this.state;

        return (
            <div className="categories-search">
                <SortLinks hidden={linksHidden} />
                <span
                    role="button"
                    tabIndex={0}
                    className={this.addOpen("search")}
                    onClick={e => this.openSearch(e)}
                    onKeyPress={e => onEnterPress(e, () => this.openSearch(e))}
                >
                    <i
                        onClick={() => this.performSearch()}
                        className="fa fa-search"
                        role="button"
                        tabIndex={0}
                        onKeyPress={e => onEnterPress(e, () => this.performSearch())}
                    />
                    <input
                        value={searchText}
                        onChange={e => this.updateSearch(e)}
                        onKeyPress={e => this.onSearchPress(e)}
                        className={this.addHidden("search-input")}
                        type="text"
                        placeholder={placeholderText}
                    />
                    <span className="search-text hidden-xs">Search</span>
                </span>
                <span
                    className="close"
                    onClick={e => this.closeSearch(e)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={e => onEnterPress(e, () => this.closeSearch(e))}
                >
                    <i className={this.addHidden("fa fa-times")} />
                </span>
            </div>
        );
    }
}

export default SearchBox;
