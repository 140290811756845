import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';


function PrevArrow(props) {
    const {className, onClick} = props;
    return (
        <button
            className={className}
            onClick={onClick}
            onKeyPress={onClick}
            type="button"
        />
    );
}

PrevArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

PrevArrow.defaultProps = {
    className: '',
};

function NextArrow(props) {
    const {className, onClick} = props;
    return (
        <button
            className={className}
            onClick={onClick}
            onKeyPress={onClick}
            type="button"
        />
    );
}

NextArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

NextArrow.defaultProps = {
    className: '',
};


class SeriesSlider extends React.PureComponent {
    render() {
        const children = [];

        let {data} = this.props;
        data = JSON.parse(data);

        for (let i = 0; i < data.length; i++) {
            const o = data[i];
            const key = `serie-${String(i)}`;
            const numText = o.number_of_posts === 1 ? 'artikkel' : 'artiklit';
            const el = (
                <div key={key} className="serie">
                    <a href={o.url} style={{backgroundImage: `url(${o.img})`}}><span /></a>
                    <div className="serie-content">
                        <span>{`${o.number_of_posts} ${numText}`}</span>
                        <h2><a href={o.url}>{o.title}</a></h2>
                        <p>{ o.excerpt }</p>
                    </div>
                </div>);
            children.push(el);
        }

        return (
            <Slider
                dots={false}
                infinite
                variableWidth
                centerMode
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
            >
                {children}
            </Slider>
        );
    }
}

SeriesSlider.propTypes = {
    data: PropTypes.string.isRequired,
};

export default SeriesSlider;
