import React, {Component} from 'react';

import getScrollbarSize from 'tg-modal/dist/utils/scrollbarSize';

import SubscribeModal from './SubscribeModal';

class StickyNavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShown: false,
            hideClass: '',
            modalOpen: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', ::this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', ::this.handleScroll);
    }

    onModalToggle() {
        this.setState(prevState => ({modalOpen: !prevState.modalOpen}));
    }

    handleScroll() {
        const shouldShow = document.getElementsByClassName('content-block')[0].offsetTop - 60 < window.pageYOffset;
        const {isShown} = this.state;

        if (isShown && !shouldShow) {
            this.setState({hideClass: ' hide-it'});

            setTimeout(() => {
                // Check if the position is still suitable for hiding or has the user moved back.
                if (document.getElementsByClassName('content-block')[0].offsetTop - 60 >= window.pageYOffset) {
                    this.setState({isShown: false});
                }
            }, 400);
        } else {
            this.setState({
                hideClass: '',
                isShown: shouldShow,
            });
        }
    }

    render() {
        const {isShown, modalOpen, hideClass} = this.state;
        if (isShown) {
            const navbarClasses = `navbar navbar-default navbar-fixed-top${hideClass}`;
            let styles = {};
            if (modalOpen) {
                styles = {paddingRight: getScrollbarSize()};
            }

            return (
                <header className={navbarClasses} role="navigation" style={styles}>
                    <div className="container">
                        <div className="navbar-collapse collapse">
                            <p className="header-text pull-left">Ära jää ilma kasulikest nippidest ja juhenditest!</p>
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <SubscribeModal
                                        onModalToggle={this.onModalToggle}
                                        signupFrom="stickyHeader"
                                        extraBtnClasses=" btn-md"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            );
        }

        return null;
    }
}

export default StickyNavBar;
