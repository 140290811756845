import queryString from 'query-string';

const getQueryParams = () => queryString.parse(window.location.search);

const updateQueryParams = (query, stripQ) => {
    let parsed = getQueryParams();
    if (query) {
        parsed = Object.assign(parsed, query);
    }

    if (stripQ || typeof stripQ === 'undefined') {
        delete parsed.q;
    }

    // This turns the query parameters back into a string.
    const stringified = queryString.stringify(parsed);

    // This will induce a page reload (which is what we want).
    // Note that the question mark is added automatically.
    window.location.search = stringified;
};


export {getQueryParams, updateQueryParams};
