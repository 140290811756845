import Router, {Resource} from 'tg-resources';

/**
 * Get csrf token
 */
export function getCsrfToken() {
    if (typeof window !== 'undefined') {
        const name = 'csrftoken';

        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = (`${cookies[i]}`).trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
                    return decodeURIComponent(cookie.substring(name.length + 1));
                }
            }
        }
    }

    return '';
}

const api = new Router({
    newsletter: new Resource(DJ_CONST.newsletterSignup),
}, {
    headers: () => ({
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
    }),
});

export default api;
