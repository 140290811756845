import React from "react";

class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShown: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', ::this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', ::this.handleScroll);
    }

    handleScroll() {
        const shouldShow = document.getElementsByClassName('content-block')[0].offsetTop - 60 < window.pageYOffset;
        const {isShown} = this.state;

        if (isShown && !shouldShow) {
            setTimeout(() => {
                // Check if the position is still suitable for hiding or has the user moved back.
                if (document.getElementsByClassName('content-block')[0].offsetTop - 60 >= window.pageYOffset) {
                    this.setState({isShown: false});
                }
            }, 400);
        } else {
            this.setState({
                isShown: shouldShow,
            });
        }
    }

    render() {
        const mobile = window.innerWidth < 767;
        let logo = null;
        const {isShown} = this.state;

        if (mobile && isShown) {
            logo = (
                <div className="visible-xs-block link">
                    Parimad postitused e-mailile
                    <div className="arrow">&rarr;</div>
                </div>
            );
        } else {
            logo = (
                <a className="navbar-brand" href="https://www.veebimajutus.ee/" title="Veebimajutus">
                    <span />
                </a>
            );
        }
        return logo;
    }
}

export default HeaderLogo;
