import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';

import LanguageSelector from 'components/LanguageSelector';

import HeaderLogo from 'components/blog/HeaderLogo';
import OffcanvasMenu from 'components/blog/OffcanvasMenu';
import SeriesSlider from 'components/blog/SeriesSlider';
import PrettyQuote from 'components/blog/PrettyQuote';
import SubscribeModal from 'components/blog/SubscribeModal';
import StickyNavBar from 'components/blog/StickyNavBar';
import SearchBox from 'components/blog/SearchBox';
import SubscribeSection from 'components/blog/SubscribeSection';

import loadMore from 'utils/load_more';


// Install Raven in production envs
if (process.env.NODE_ENV === 'production') {
    Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
    // handle rejected promises
    window.addEventListener('unhandledrejection', (evt) => {
        Raven.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Raven
    if (DJ_CONST.user) {
        Raven.setUserContext({
            id: DJ_CONST.user.id,
            email: DJ_CONST.user.email,
            name: DJ_CONST.user.name,
        });
    }
}

function subscribeButton() {
    const subscribeElem = document.getElementById("subscribe");
    if (!subscribeElem) {
        return;
    }

    ReactDOM.render(<SubscribeModal signupFrom="headerButton" />, subscribeElem);
}

function subscribeModalForXs() {
    const subscribeElem = document.getElementById("subscribe-no-button");
    if (!subscribeElem) {
        return;
    }

    ReactDOM.render(<SubscribeModal noButton signupFrom="subscribeNoButton" />, subscribeElem);
}

function stickyHeader() {
    const stickyHeaderElem = document.getElementById('sticky-header');
    if (!stickyHeaderElem) {
        return;
    }

    ReactDOM.render(<StickyNavBar />, stickyHeaderElem);
}

function headerLogo() {
    const headerLogoElem = document.getElementById('logo-container');

    if (!headerLogoElem) {
        return;
    }

    ReactDOM.render(<HeaderLogo />, headerLogoElem);
}

function mobileSubscribe() {
    const mobileSubscribeElem = document.getElementById('subscribe-btn-nav');

    if (!mobileSubscribeElem || window.innerWidth > 766) {
        return;
    }

    ReactDOM.render(<SubscribeModal
        signupFrom="mobileStickyHeader"
        extraBtnClasses=" btn-small sticky-header"
    />, mobileSubscribeElem);
}

function makeSubscribeSection() {
    const subscribeElem = document.getElementById("inline-subscribe");
    if (!subscribeElem) {
        return;
    }

    ReactDOM.render(<SubscribeSection signupFrom="inlineSubscribeButton" />, subscribeElem);
}

function searchBox() {
    const searchEl = document.getElementById('haystack-search-box');
    if (!searchEl) {
        return;
    }

    ReactDOM.render(<SearchBox />, searchEl);
}

function quotes() {
    const article = document.getElementById("post");
    if (!article) {
        return;
    }

    const tweetQuotes = article.getElementsByClassName('tweet-quote');
    if (tweetQuotes.length === 0) {
        return;
    }

    Array.prototype.forEach.call(tweetQuotes, (quote) => {
        const quoteText = quote.innerText;
        ReactDOM.render(<PrettyQuote content={quoteText} />, quote);
    });
}

function offcanvasMenu() {
    const menu = document.getElementById("oc-menu");
    if (!menu) {
        return;
    }

    ReactDOM.render(<OffcanvasMenu />, menu);
}

function addLoadMoreListener() {
    document.addEventListener("click", (e) => {
        if (e.target.id === "load-more") {
            loadMore(e.target);
        }
    });
}

function addSeriesSlider() {
    const slider = document.getElementById("series-slider-area");
    if (!slider) {
        return;
    }

    ReactDOM.render(<SeriesSlider data={slider.dataset.children} />, slider);
}

function addLinkTracking() {
    const articleLinks = document.querySelectorAll('article > p > a');
    if (articleLinks) {
        articleLinks.forEach((node) => {
            node.addEventListener('mousedown',
                (e) => {
                    e.preventDefault();
                    // eslint-disable-next-line no-undef
                    ga('gtm1.send', {
                        hitType: 'event',
                        eventCategory: 'BlogPostContentInteraction',
                        eventAction: 'LinkClick',
                        eventLabel: e.target.href,
                    });
                });
        });
    }
}


function initBlog() {
    subscribeButton();
    makeSubscribeSection();
    quotes();
    offcanvasMenu();
    addLoadMoreListener();
    subscribeModalForXs();
    stickyHeader();
    headerLogo();
    mobileSubscribe();
    searchBox();
    addSeriesSlider();
    addLinkTracking();
}

function makeLanguageSelector() {
    const selector = document.querySelector("#language-selector");
    if (!selector) {
        return;
    }

    const activeLanguage = DJ_CONST.ACTIVE_LANGUAGE;

    // Django likes to use single quotes, but javascript does not like them.
    const activeLanguages = JSON.parse(DJ_CONST.AVAILABLE_LANGUAGES.replace(/'/g, '"'));
    const languageDomains = JSON.parse(DJ_CONST.LANGUAGE_DOMAINS.replace(/'/g, '"'));
    const languageAbbreviations = JSON.parse(DJ_CONST.LANGUAGE_ABBREVIATIONS.replace(/'/g, '"'));

    ReactDOM.render((
        <LanguageSelector
            activeLanguage={activeLanguage}
            languages={activeLanguages}
            languageAbbreviations={languageAbbreviations}
            languageDomains={languageDomains}
        />
    ), selector);
}

export {
    initBlog,
    makeLanguageSelector,
};
