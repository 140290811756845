import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Modal from 'tg-modal';
import api from '../../utils/router';
import PeekingFox from './PeekingFox';


class SubscribeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            email: '',
            error: false,
            sent: false,
        };

        this.subscribeForm = React.createRef();
    }

    componentDidMount() {
        if (window.location.hash === "#uudiskiri") {
            this.setState({
                isOpen: true,
            });
        }
    }

    onChange(e) {
        this.setState({email: e.target.value, error: false});
    }

    resetState() {
        this.setState({
            isOpen: false,
            email: "",
            error: false,
            sent: false,
        });

        const {onModalToggle} = this.props;
        onModalToggle();
    }

    toggleModal() {
        this.setState(prevState => ({isOpen: !prevState.isOpen}));

        const {onModalToggle} = this.props;
        onModalToggle();
    }

    handleSubmit(e) {
        const isValid = e.target.checkValidity();
        e.preventDefault();
        if (!isValid) {
            this.setState({error: true});
        } else {
            const payload = {
                email: this.subscribeForm.current.email.value,
            };

            api.newsletter.post(null, payload);
            this.setState({sent: true});
        }

        const {signupFrom} = this.props;
        // eslint-disable-next-line no-undef
        ga('gtm1.send', {
            hitType: 'event',
            eventCategory: 'BlogSignup',
            eventAction: 'signup',
            eventLabel: signupFrom,
        });
    }

    render() {
        const isMobile = window.innerWidth < 767;

        // First render this as hidden so that the user doesn't have to wait for FB loading afterwards
        let fbBoxClass = 'hide';
        const {sent} = this.state;
        if (sent) {
            fbBoxClass = '';
        }

        const fbBoxWidth = isMobile ? '222' : '390';
        const fbBox = (
            <iframe
                // eslint-disable-next-line max-len
                src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fveebimajutus%2F&tabs&width=${fbBoxWidth}&height=215&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1452779658271305`}
                width={fbBoxWidth}
                height="215"
                style={{border: 'none', overflow: 'hidden'}}
                scrolling="no"
                frameBorder="0"
                allowTransparency="true"
                title="fb-box"
                className={fbBoxClass}
            />
        );

        const {email, error, isOpen} = this.state;

        const form = (
            <div>
                <h3 className="modal-title">
                    Uudised, õpetused ja kavalad veebinipid. Otse meilile.
                </h3>
                <span className="tagline tagline-blue">Sisesta oma e-mail ja liitu meie nädalakirjaga:</span>
                <form id="modal-subscribe-form" onSubmit={::this.handleSubmit} ref={this.subscribeForm}>
                    <input
                        className="subscription-email"
                        type="email"
                        name="email"
                        onChange={::this.onChange}
                        value={email}
                        placeholder="Sisesta e-mail"
                        required
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    />
                    {error ? <span className="form-error">E-postiaadress ei ole korrektne.</span> : null}
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!email}
                        formNoValidate
                    >
                        Telli nädalakiri
                    </button>
                </form>
            </div>
        );

        const submitMessage = (
            <div>
                <h3>Hakka Facebookis meie fänniks!</h3>
            </div>
        );

        const closeBtn = (
            <button className="btn btn-primary" onClick={::this.resetState} type="button">
                Sulge
            </button>
        );

        const {extraBtnClasses, noButton} = this.props;

        const buttonTxt = isMobile ? 'Telli' : 'Telli nädalakiri e-postile';
        const buttonClasses = `btn btn-overlay${extraBtnClasses}`;

        return (
            <div>
                {noButton ? null : (
                    <button className={buttonClasses} onClick={::this.toggleModal} type="button">
                        {buttonTxt}
                    </button>
                )}

                <Modal isOpen={isOpen} title=" " onCancel={() => this.resetState()}>
                    <Modal.Body className="modal-body narrow-padding">
                        <PeekingFox />
                        {(sent) ? submitMessage : form}
                        <div className="modal-fb-box">
                            {fbBox}
                        </div>
                        {(sent) ? closeBtn : null}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

SubscribeModal.propTypes = {
    onModalToggle: PropTypes.func,
    signupFrom: PropTypes.string,
    extraBtnClasses: PropTypes.string,
    noButton: PropTypes.bool,
};

SubscribeModal.defaultProps = {
    onModalToggle: () => {},
    extraBtnClasses: '',
    signupFrom: '',
    noButton: false,
};

export default SubscribeModal;
