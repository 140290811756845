import React, {Component} from 'react';
import Modal from 'tg-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PeekingFox from './PeekingFox';
import SubscribeButton from './SubscribeButton';

import api from '../../utils/router';


class SubscribeForm extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            isOpen: false,
            email: "",
            error: false,
            submitMessage: "",
        };

        this.state = this.defaultState;
        this.inlineSubscribeForm = React.createRef();
    }

    onChange = (e) => {
        this.setState({email: e.target.value, error: false});
    };

    resetState = () => {
        this.setState(this.defaultState);
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const isValid = e.target.checkValidity();

        if (!isValid) {
            this.setState({error: true});
        } else {
            const {email} = this.state;
            const payload = {email};

            const onLoad = (result) => {
                this.setState({submitMessage: result.message});

                const {setNeedsToSubscribe} = this.props;
                setNeedsToSubscribe(false);
            };

            const onError = (result) => {
                if (result.responseText !== "" && result.responseText.charAt(0) !== "<") {
                    this.setState({submitMessage: JSON.parse(result.responseText).message});
                } else {
                    this.setState({submitMessage: "Midagi läks valesti. Proovi hiljem uuesti!"});
                }
            };

            api.newsletter.post(null, payload).then(onLoad).catch(onError);
            this.setState({isOpen: true});
        }

        let source;
        if (!DJ_CONST.subscribed && (DJ_CONST.locked || DJ_CONST.subscriber_only)) {
            source = 'lockedArticleBanner';
        } else {
            const {signupFrom} = this.props;
            source = signupFrom;
        }

        // eslint-disable-next-line no-undef
        ga('gtm1.send', {
            hitType: 'event',
            eventCategory: 'BlogSignup',
            eventAction: 'signup',
            eventLabel: source,
        });

        DJ_CONST.subscribed = true;
    };

    renderInput(styles) {
        const {email} = this.state;
        return (
            <input
                className="subscription-email"
                type="email"
                name="email"
                onChange={this.onChange}
                value={email}
                placeholder="Sisesta e-mail"
                required
                style={styles}
            />
        );
    }

    renderError() {
        const {error} = this.state;
        if (!error) {
            return null;
        }

        const {isLocked} = this.props;
        return (
            <span
                className={classNames("form-error", {locked: isLocked})}
            >
                E-postiaadress ei ole korrektne.
            </span>
        );
    }


    renderLockedForm() {
        const buttonWidth = "240px";

        const CheckBox = props => (
            <div className="checkincircle">
                <div className="blue-check" />
                <span className="checkincircle-text">{props.children}</span>
            </div>
        );

        const {email, error} = this.state;

        return (
            <div>
                <div style={{height: '37px'}} />
                <form id="blog-subscribe" onSubmit={this.handleSubmit} ref={this.inlineSubscribeForm}>
                    <div className="subscribe-form-locked-checkboxes">
                        <CheckBox>Kasulikud nipid ja juhendid</CheckBox>
                        <CheckBox>Põnevad intervjuud ekspertidega</CheckBox>
                        <CheckBox>Veebiteemalised uudised</CheckBox>
                    </div>
                    <div id="subscribe-form-locked">
                        {this.renderError()}
                        {this.renderInput({display: "block", width: buttonWidth})}
                        <SubscribeButton
                            disabled={!email || error}
                            isLocked
                            styles={{width: buttonWidth, "margin-top": "7px", "margin-right": "11px"}}
                        />
                    </div>
                </form>
                <div style={{height: '56px'}} />
            </div>
        );
    }

    renderOpenForm() {
        const {email, error} = this.state;

        return (
            <div>
                <div style={{height: '37px'}} />
                <form id="blog-subscribe" onSubmit={this.handleSubmit} ref={this.inlineSubscribeForm}>
                    {this.renderInput()}
                    <SubscribeButton disabled={!email || error} />

                    {this.renderError()}
                </form>
                <div style={{height: '56px'}} />
            </div>
        );
    }

    render() {
        let form = null;
        const {isLocked, needsToSubscribe} = this.props;
        const {isOpen, submitMessage} = this.state;

        if (isLocked) {
            form = this.renderLockedForm();
        } else {
            form = this.renderOpenForm();
        }

        if (!needsToSubscribe && !isOpen) {
            form = null;
        }

        return (
            <div>
                {form}
                <Modal
                    isOpen={isOpen}
                    autoWrap
                    title=" "
                    onCancel={() => this.resetState()}
                >
                    <PeekingFox />
                    <div>
                        <h3>{submitMessage}</h3>
                    </div>
                </Modal>
            </div>
        );
    }
}

SubscribeForm.propTypes = {
    setNeedsToSubscribe: PropTypes.func,
    needsToSubscribe: PropTypes.bool,
    isLocked: PropTypes.bool,
    signupFrom: PropTypes.string.isRequired,
};

SubscribeForm.defaultProps = {
    setNeedsToSubscribe: () => {},
    needsToSubscribe: false,
    isLocked: false,
};

export default SubscribeForm;
