import React from 'react';
import PropTypes from 'prop-types';


class SubscribeButton extends React.PureComponent {
    render() {
        let continueReadingElement;
        let includeStyles = false;

        const {isLocked, styles, disabled} = this.props;

        if (!isLocked) {
            continueReadingElement = (
                <div className="continue-reading-wrap">
                    <p>...või jätka lugemist</p>
                </div>
            );
        } else {
            includeStyles = true;
        }

        return (
            <button
                className="btn btn-primary"
                type="submit"
                disabled={disabled}
                formNoValidate
                style={includeStyles ? styles : {}}
            >
                Telli
                {continueReadingElement}
            </button>
        );
    }
}

SubscribeButton.propTypes = {
    disabled: PropTypes.bool,
    isLocked: PropTypes.bool,
    styles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

SubscribeButton.defaultProps = {
    disabled: false,
    isLocked: false,
    styles: {},
};

export default SubscribeButton;
