import React, {Component} from 'react';
import classNames from 'classnames';

import FaceBookIframe from './FaceBookIframe';
import SubscribeForm from './SubscribeForm';


class SubscribeSection extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            needsToSubscribe: (
                DJ_CONST.subscriber_only && !DJ_CONST.subscribed)
                || (DJ_CONST.locked && !DJ_CONST.subscribed),
            isLocked: DJ_CONST.locked,
            isOpen: false,
            email: "",
            error: false,
            sent: false,
            submitMessage: "",
        };

        this.state = this.defaultState;
    }

    setNeedsToSubscribe = (bool) => {
        this.setState({needsToSubscribe: bool});
    };

    render() {
        const titleTexts = [
            'Kasulikud nipid ja juhendid otse e-mailile!',
            'Jälgi meid Facebookis!',
            'Ohoh, rebane on selle postituse oma käpa alla pannud',
        ];
        const subtitleTexts = [
            'Kiri tuleb Sulle korra nädalas ning iga hetk võid saatmisest loobuda',
            '',
            'Liitu uudiskirjaga ja jätka lugemist',
        ];

        const {needsToSubscribe, isLocked} = this.state;

        let textIndex = needsToSubscribe ? 0 : 1;
        if (isLocked && needsToSubscribe) {
            textIndex = 2;
        }

        const isMobile = window.innerWidth < 767;

        return (
            <div>
                <section className={classNames('light-block', {'split-end': needsToSubscribe})}>
                    <div className="subscriber-only shown">
                        <h3 className="light-block-title">{titleTexts[textIndex]}</h3>
                        <p className="tagline tagline-black">{subtitleTexts[textIndex]}</p>
                    </div>
                    <SubscribeForm
                        setNeedsToSubscribe={this.setNeedsToSubscribe}
                        needsToSubscribe={needsToSubscribe}
                        isLocked={isLocked}
                        {...this.props}
                    />
                    <FaceBookIframe
                        needsToSubscribe={needsToSubscribe}
                        isLocked={isLocked}
                    />
                    {isMobile ? null : <div style={{height: '40px'}} />}
                </section>
            </div>
        );
    }
}

export default SubscribeSection;
