import React, {Component} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import {onEnterPress} from "utils/onKeyPress";
import {getQueryParams, updateQueryParams} from './utils';

import CategorySelect from '../CategorySelect';


class SortLinks extends Component {
    addHidden = (c) => {
        const {hidden} = this.props;
        return classNames(c, {hidden});
    };

    getActiveClasses = () => {
        // Default return value;
        let returnList = ['', 'active'];

        // Default sort type;
        let sortType = 'newest';

        // Update the sortType from the current URL query params.
        const queryParams = getQueryParams();
        if (Object.keys(queryParams).includes('sort')) {
            sortType = queryParams.sort;
        }

        if (sortType === 'popular') {
            returnList = ['active', ''];
        }

        // Turn into class string and add hidden class, if required.
        return returnList.map(this.addHidden);
    }

    render() {
        const [popularClasses, newestClasses] = this.getActiveClasses();
        let mobileCategorySelect;
        if (window.innerWidth < 767) {
            mobileCategorySelect = (<CategorySelect />);
        }

        return (
            <ul>
                <li>
                    <button
                        onClick={() => updateQueryParams({sort: 'newest'}, false)}
                        onKeyPress={event => onEnterPress(event, () => updateQueryParams({sort: 'newest'}, false))}
                        className={newestClasses}
                        type="button"
                    >
                        Recent
                    </button>
                </li>
                <li>
                    <button
                        onClick={() => updateQueryParams({sort: 'popular'}, false)}
                        className={popularClasses}
                        onKeyPress={event => onEnterPress(event, () => updateQueryParams({sort: 'newest'}, false))}
                        type="button"
                    >
                        Most Popular
                    </button>
                </li>
                <li id="mobile-cate-select" className={this.addHidden('')}>
                    {mobileCategorySelect}
                </li>
            </ul>
        );
    }
}

SortLinks.propTypes = {
    hidden: PropTypes.bool,
};

SortLinks.defaultProps = {
    hidden: '',
};

export default SortLinks;
