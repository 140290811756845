import React from 'react';
import PropTypes from 'prop-types';


class PrettyQuote extends React.PureComponent {
    render() {
        const {content} = this.props;
        return (
            <div>
                <blockquote className="quote">
                    <p className="quote-text">{content}</p>
                </blockquote>

                <a
                    className="twitter-box"
                    href={`https://twitter.com/intent/tweet?text=${content}&url=${window.location.href}`}
                >
                    <i className="fa fa-twitter" aria-hidden="true" />
                </a>
            </div>
        );
    }
}

PrettyQuote.propTypes = {
    content: PropTypes.string.isRequired,
};

export default PrettyQuote;
