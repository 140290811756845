import React, {Component} from 'react';

class CategorySelect extends Component {
    static onClick(e) {
        if (e) {
            e.preventDefault();
        }
    }

    static onKeyPress(e) {
        if (e.key === 'Enter') {
            this.onClick(e);
        }
    }

    static onSelect(e) {
        const prefix = DJ_CONST.DEBUG === 'True' ? '' : '/blogi';

        if (e.target.value) {
            window.location.href = `${prefix}/kategooria/${e.target.value}${window.location.search}`;
        } else {
            window.location.href = `${prefix}/${window.location.search}`;
        }
    }

    render() {
        let defaultVal = '';
        const choices = (DJ_CONST.categories || []).map((item) => {
            if (window.location.pathname.indexOf(item.slug) > -1) {
                defaultVal = item.slug;
            }
            return (
                <option key={item.slug} value={item.slug}>
                    {item.name}
                </option>
            );
        });

        return (
            <button
                className="cate-select"
                onClick={this.constructor.onClick}
                onKeyPress={this.constructor.onKeyPress}
                type="button"
            >
                <span className="cate-select-label">Kategooria</span>
                <select id="cate-select" onChange={event => this.constructor.onSelect(event)} defaultValue={defaultVal}>
                    <option value="">Kõik artiklid</option>
                    {choices}
                </select>
            </button>
        );
    }
}

export default CategorySelect;
