import React from 'react';


function PeekingFox() {
    return (
        <div id="modal-fox">
            <div id="modal-fox-left-paw" />
            <div id="modal-fox-head" />
            <div id="modal-fox-right-paw" />
        </div>
    );
}

export default PeekingFox;
