function onEnterPress(event, callback) {
    if (event.key === 'Enter') {
        callback();
    }
}

function onEscapePress(event, callback) {
    if (event.keyCode === 27) {
        callback();
    }
}

export {
    onEnterPress,
    onEscapePress,
};
